const NETMedia = [
    { value: "Cable", label: "Cable", MediaType: "Cable", Code: "CA", Selected: false },
    { value: "National", label: "National", MediaType: "National", Code: "NA", Selected: false },
    { value: "Syndication", label: "Syndication", MediaType: "Syndication", Code: "SD", Selected: false },
]
const NETMediaForVendor = [
    { value: "Cable FEPS treaming", label: "Cable FEP Streaming", MediaType: "Cable FEP Streaming", Code: "CA", Selected: false },
    { value: "Cable VOD", label: "Cable VOD", MediaType: "Cable VOD", Code: "NA", Selected: false },
    { value: "Network Streaming", label: "Network Streaming", MediaType: "Network Streaming", Code: "SD", Selected: false },
]

const SPOTMedia = [
    { value: "TV", label: "TV", MediaType: "TV", Code: "CA", Selected: false },
    { value: "Radio", label: "Radio", MediaType: "Radio", Code: "CA", Selected: false },
    { value: "Network Radio", label: "Network Radio", MediaType: "Network Radio", Code: "CA", Selected: false },
]

const PRINTMedia = [
    { value: "Magazine", label: "Magazine", MediaType: "Magazine", Code: "MG", Selected: false },
    { value: "Interactive", label: "Interactive", MediaType: "Interactive", Code: "IA", Selected: false },
    { value: "Newspaper", label: "Newspaper", MediaType: "Newspaper", Code: "NP", Selected: false },
    { value: "Outdoor", label: "Outdoor", MediaType: "Outdoor", Code: "OD", Selected: false },
    { value: "Trade", label: "Trade", MediaType: "Trade", Code: "TR", Selected: false },
    { value: "Search", label: "Search", MediaType: "Search", Code: "SR", Selected: false },
    { value: "Social", label: "Social", MediaType: "Social", Code: "SC", Selected: false },
    { value: "Video", label: "Video", MediaType: "Video", Code: "VD", Selected: false },
    { value: "Bundled", label: "Bundled", MediaType: "Bundled", Code: "BN", Selected: false },
    { value: "Unbundled", label: "Unbundled", MediaType: "Unbundled", Code: "UB", Selected: false },
    { value: "Digital Audio", label: "Digital Audio", MediaType: "Digital Audio", Code: "DA", Selected: false },
]
const PRINTCategoryMedia = [
    { value: "Magazine", label: "Magazine", MediaType: "Magazine", Code: "MG", Selected: false },
    { value: "Newspaper", label: "Newspaper", MediaType: "Newspaper", Code: "NP", Selected: false },
    { value: "Outdoor", label: "Outdoor", MediaType: "Outdoor", Code: "OD", Selected: false },
    { value: "Trade", label: "Trade", MediaType: "Trade", Code: "TR", Selected: false },
]

const DIGITALMedia = [
    { value: "Search", label: "Search", MediaType: "Search", Code: "SR", Selected: false },
    { value: "Interactive", label: "Interactive", MediaType: "Interactive", Code: "IA", Selected: false },
    { value: "Social", label: "Social", MediaType: "Social", Code: "SC", Selected: false },
    { value: "Video", label: "Video", MediaType: "Video", Code: "VD", Selected: false },
    { value: "Bundled", label: "TVBundled", MediaType: "Bundled", Code: "BN", Selected: false },
    { value: "Unbundled", label: "Unbundled", MediaType: "Unbundled", Code: "UB", Selected: false },
    { value: "Digital Audio", label: "Digital Audio", MediaType: "Digital Audio", Code: "DA", Selected: false },
]

const PRINTWithDigitalCategoryMedia = [
    { value: "Magazine", label: "Magazine", MediaType: "Magazine", Code: "MG", Selected: false },
    { value: "Newspaper", label: "Newspaper", MediaType: "Newspaper", Code: "NP", Selected: false },
    { value: "Outdoor", label: "Outdoor", MediaType: "Outdoor", Code: "OD", Selected: false },
    { value: "Trade", label: "Trade", MediaType: "Trade", Code: "TR", Selected: false },
    { value: "Digital", label: `Digital(${DIGITALMedia.map(d => d.value).join(",")})`, MediaType: "Digital", Code: "DG", Selected: false },
]

const AllDigitalMedia = ["Search", "Interactive", "Social", "Video", "Bundled", "Unbundled", "Digital Audio"];

const NETWithoutDigital = NETMedia.concat(NETMediaForVendor).filter(media => {
    return !AllDigitalMedia.includes(media.value);
});
const PRINTWithoutDigital = PRINTMedia.filter(media => {
    return !AllDigitalMedia.includes(media.value);
});
const SPOTWithoutDigital = SPOTMedia.filter(media => {
    return !AllDigitalMedia.includes(media.value);
});

const NETDigital = NETMedia.concat(NETMediaForVendor).filter(media => {
    return AllDigitalMedia.includes(media.value);
});
const PRINTDigital = PRINTMedia.filter(media => {
    return AllDigitalMedia.includes(media.value);
});
const SPOTDigital = SPOTMedia.filter(media => {
    return AllDigitalMedia.includes(media.value);
});

export {
    NETMedia, PRINTMedia, SPOTMedia, DIGITALMedia,
    PRINTCategoryMedia, PRINTWithDigitalCategoryMedia, AllDigitalMedia
    , NETWithoutDigital, PRINTWithoutDigital, SPOTWithoutDigital
    ,NETDigital,PRINTDigital,SPOTDigital
};